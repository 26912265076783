import { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { GatsbyImage } from 'gatsby-plugin-image'

import FlexSection from '@components/FlexSection'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, A11y, Navigation } from 'swiper'

SwiperCore.use([Autoplay, A11y, Navigation])

import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

import { componentLoaded, findColors } from '@utils/functions'

import {
  WpPage_Acfpagefields_Content_SimpleCarousel,
  WpPrograma_Acfpagefields_Content_SimpleCarousel
} from '@_types/graphql-types'

type contentType =
  | WpPage_Acfpagefields_Content_SimpleCarousel
  | WpPrograma_Acfpagefields_Content_SimpleCarousel

const Carousel = ({
  data,
  index,
  tabs
}: {
  data: contentType
  index: string
  tabs?: boolean
}) => {
  const { images, perView, title, bgColor, imageSizes, tagTitle } = data

  const sectionColors = findColors(bgColor)

  useEffect(() => {
    componentLoaded(title)
  }, [])

  const imagesPerView = (n: number) => {
    if (n < 1 || n === 1 || !n)
      return {
        mobile: 1,
        tablet: 1,
        desktop: 1
      }

    if (n > 1 && n <= 3)
      return {
        mobile: 1.3,
        tablet: 2,
        desktop: n
      }

    return {
      mobile: n / 1.75 > 1.3 ? 1.3 : n / 1.75,
      tablet: n / 1.75,
      desktop: n
    }
  }

  return (
    <FlexSection
      index={index}
      title={title}
      tagTitle={tagTitle}
      colors={sectionColors}
      id={title || ''}
      alignTitle="center"
      paddingBottom
      tabs={tabs}
    >
      <SRLWrapper
        options={{
          buttons: {
            showAutoplayButton: false,
            showDownloadButton: false
          }
        }}
      >
        <SwiperContainer>
          <Swiper
            slidesPerView={imagesPerView(perView).mobile}
            spaceBetween={16}
            navigation
            a11y={{
              prevSlideMessage: 'Anterior',
              nextSlideMessage: 'Próximo',
              firstSlideMessage: 'Primeiro',
              lastSlideMessage: 'Último',
              paginationBulletMessage:
                'Foto {currentSlideIndex} de {totalSlides}',
              containerMessage: 'Galeria de fotos'
            }}
            autoplay={{
              delay: 5000
            }}
            breakpoints={{
              600: {
                slidesPerView: imagesPerView(perView).tablet,
                spaceBetween: 24
              },
              1024: {
                slidesPerView: imagesPerView(perView).desktop,
                spaceBetween: 64
              }
            }}
          >
            {images &&
              images.map(({ image }, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div tw="flex justify-center items-center h-full">
                      <GatsbyImage
                        image={
                          imageSizes === 'min'
                            ? image.localFile.childImageSharp['min']
                            : imageSizes === 'mid'
                            ? image.localFile.childImageSharp['large']
                            : image.localFile.childImageSharp['full']
                        }
                        alt={image.altText}
                        objectFit="contain"
                        tw="w-full h-full cursor-pointer"
                        css={[
                          imageSizes === 'min'
                            ? tw`max-w-[100px] max-h-[100px]`
                            : imageSizes === 'mid'
                            ? tw`max-w-[400px] max-h-[400px]`
                            : tw`max-w-[600px] max-h-[600px]`
                        ]}
                      />
                    </div>
                  </SwiperSlide>
                )
              })}
          </Swiper>
        </SwiperContainer>
      </SRLWrapper>
    </FlexSection>
  )
}

const SwiperContainer = styled.div`
  .swiper-wrapper {
    ${tw`items-stretch`}
  }
  .swiper-slide {
    ${tw`h-auto`}
  }

  .swiper-pagination {
    ${tw`bottom-0! z-10`}

    .swiper-pagination-bullet {
      ${tw`w-7 rounded-md h-1 bg-gray-500!`}

      &-active {
        ${tw`bg-lemann-dark-orange!`}
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    ${tw`text-lemann-dark-orange!`}
  }
`

export default Carousel
